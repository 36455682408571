import React from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import Avatar from "~/components/Avatar/Avatar"
import { Col, Heading, Row } from "~/components/ui"

export default function AvatarSample() {
  return (
    <>
      <Section title="Avatar">
        <Heading>ExtraLarge (72px | 216px)</Heading>
        <Row>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture:
                  "https://cdn.chefclub.tools/uploads/users/profile_picture/4a3a91ec-33b2-4958-b8f9-de686f47b0fe.jpg",
                userTypes: ["creator"],
              }}
              variant="extraLarge"
            />
          </Col>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture: null,
                userTypes: ["creator"],
              }}
              variant="extraLarge"
            />
          </Col>
        </Row>

        <Heading>large (56px)</Heading>
        <Row>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture:
                  "https://cdn.chefclub.tools/uploads/users/profile_picture/4a3a91ec-33b2-4958-b8f9-de686f47b0fe.jpg",
                userTypes: ["creator"],
              }}
              variant="large"
            />
          </Col>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture: null,
                userTypes: ["creator"],
              }}
              variant="large"
            />
          </Col>
        </Row>

        <Heading>medium (48px)</Heading>
        <Row>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture:
                  "https://cdn.chefclub.tools/uploads/users/profile_picture/4a3a91ec-33b2-4958-b8f9-de686f47b0fe.jpg",
                userTypes: ["creator"],
              }}
              variant="medium"
            />
          </Col>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture: null,
                userTypes: ["creator"],
              }}
              variant="medium"
            />
          </Col>
        </Row>

        <Heading>small (40px)</Heading>
        <Row>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture:
                  "https://cdn.chefclub.tools/uploads/users/profile_picture/4a3a91ec-33b2-4958-b8f9-de686f47b0fe.jpg",
                userTypes: ["creator"],
              }}
              variant="small"
            />
          </Col>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture: null,
                userTypes: ["creator"],
              }}
              variant="small"
            />
          </Col>
        </Row>

        <Heading>extraSmall (28px)</Heading>
        <Row>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture:
                  "https://cdn.chefclub.tools/uploads/users/profile_picture/4a3a91ec-33b2-4958-b8f9-de686f47b0fe.jpg",
                userTypes: ["creator"],
              }}
              variant="extraSmall"
            />
          </Col>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture: null,
                userTypes: ["creator"],
              }}
              variant="extraSmall"
            />
          </Col>
        </Row>

        <Heading>extraExtraSmall (24px)</Heading>
        <Row>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture:
                  "https://cdn.chefclub.tools/uploads/users/profile_picture/4a3a91ec-33b2-4958-b8f9-de686f47b0fe.jpg",
                userTypes: ["creator"],
              }}
              variant="extraExtraSmall"
            />
          </Col>
          <Col width={[6, 6, 6]}>
            <Avatar
              user={{
                username: "ChefClub",
                id: "ChefClub",
                profilePicture: null,
                userTypes: ["creator"],
              }}
              variant="extraExtraSmall"
            />
          </Col>
        </Row>
      </Section>
    </>
  )
}
