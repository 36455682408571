import React, { useState } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import { Body, Col, Row } from "~/components/ui"
import Checkbox from "~/components/ui/Checkbox/Checkbox"

export default function CheckboxSample() {
  const [isCheck, setIsCheck] = useState(false)
  return (
    <>
      <Section title="Checkbox">
        <Row>
          <Col width={[6, 6, 6]}>
            <Checkbox
              label={"Adrian"}
              value={["Adrian"]}
              defaultChecked={isCheck}
              onclick={() => {
                console.log("Check")
                setIsCheck(!isCheck)
              }}
            >
              <Body variant="body2" semiBold>
                Adrian
              </Body>
            </Checkbox>
          </Col>
        </Row>
      </Section>
    </>
  )
}
