import React, { useState } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import { Col, Heading, Row } from "~/components/ui"
import VisibilityTransition from "~/components/VisibilityTransition/VisibilityTransition"

const Placeholder = (props: { name: string }) => (
  <img
    loading="lazy"
    src={`https://via.placeholder.com/500?text=${props.name}`}
    style={{ maxWidth: "100%" }}
  />
)

export default function VisibilityTransitionSample() {
  const [inProp, setInProp] = useState(false)

  const toggleButton = (
    <button
      type="button"
      style={{ marginBottom: 16 }}
      onClick={() => setInProp(prev => !prev)}
    >
      Toggle visibility
    </button>
  )

  return (
    <Section title="Visibility Transition (onMount | onUnMount)">
      <Heading>A. Basic - one animation</Heading>
      {toggleButton}
      <Row style={{ minHeight: 300 }}>
        <Col width={4}>
          <VisibilityTransition show={inProp} variant="fade">
            <Placeholder name="Fade in" />
          </VisibilityTransition>
        </Col>
        <Col width={4}>
          <VisibilityTransition show={inProp} variant="scale">
            <Placeholder name="Scale in" />
          </VisibilityTransition>
        </Col>
        <Col width={4}>
          <VisibilityTransition show={inProp} variant={["fromTop"]}>
            <Placeholder name="From Top" />
          </VisibilityTransition>
        </Col>
      </Row>

      <Heading>B. Combined Visibility Transition</Heading>
      {toggleButton}
      <Row style={{ minHeight: 300 }}>
        <Col width={4}>
          <VisibilityTransition show={inProp} variant={["fade", "scale"]}>
            <Placeholder name="Fade and scale" />
          </VisibilityTransition>
        </Col>
        {/* TODO: Broken, we cannot use several transform properties together */}
        {/* <Col width={4}>
          <VisibilityTransition show={inProp} variant={["scale", "fromTop"]}>
            <Placeholder name="Scale and from top" />
          </VisibilityTransition>
        </Col> */}
        <Col width={4}>
          <VisibilityTransition show={inProp} variant={["fade", "fromTop"]}>
            <Placeholder name="Fade and From Top" />
          </VisibilityTransition>
        </Col>
      </Row>
    </Section>
  )
}
