import React from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import { Body, Heading } from "~/components/ui"

const loremIpsum = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
  Ut enim ad minim veniam, quis nostrud exercitation ullamco 
  laboris nisi ut aliquip ex ea commodo consequat.
`

export default function TypographySample() {
  return (
    <>
      <Section title="New Fonts">
        <pre>
          <code>{`<Heading variant? {...TypographyProps} />`}</code>
          <br />
          <code>{`<FormattedHeading translationId variant? {...TypographyProps}/>`}</code>
        </pre>

        <Heading variant="modal" uppercase>
          Modal Title
        </Heading>
        <Heading variant="extraLarge">Extra-large title</Heading>
        <Heading /*variant="large"*/>Large title (default)</Heading>
        <Heading variant="medium">Medium title</Heading>
        <Heading variant="small">Small title</Heading>
        <Heading variant="extraSmall">Extra-small</Heading>

        <Heading variant="extraLargeHandDrawn">Extra-large hand drawn</Heading>
        <Heading variant="largeHandDrawn">Large hand drawn</Heading>
        <Heading variant="mediumHandDrawn">Medium hand drawn</Heading>
        <Heading variant="smallHandDrawn">Small hand drawn</Heading>
        <Heading variant="extraSmallHandDrawn">Extra-small hand drawn</Heading>

        <Heading variant="extraLargeCampingHoliday">
          Extra-large camping holiday
        </Heading>
        <Heading variant="largeCampingHoliday">Large camping holiday</Heading>
        <Heading variant="mediumCampingHoliday">Medium camping holiday</Heading>
        <Heading variant="smallCampingHoliday">Small camping holiday</Heading>
        <Heading variant="extraSmallCampingHoliday">
          Extra-small camping holiday
        </Heading>
        <Heading variant="extraExtraLargeCreators">
          Extra-Extra-large creators
        </Heading>
        <Heading variant="extraLargeCreators">Extra-large creators</Heading>
        <Heading variant="largeCreators">Large creators</Heading>
        <Heading variant="mediumCreators">Medium creators</Heading>
        <Heading variant="smallCreators">Small creators</Heading>
        <Heading variant="extraSmallCreators">Extra-small creators</Heading>

        <br />
        <hr />
        <br />

        <pre>
          <code>{`<Body variant? {...TypographyProps} />`}</code>
          <br />
          <code>{`<FormattedBody translationId variant? {...TypographyProps}/>`}</code>
        </pre>

        <Body /*variant="body1"*/>Body 1 (default)</Body>
        <Body /*variant="body1"*/ bold>Body 1 semi bold</Body>

        <Body variant="body2">Body 2</Body>
        <Body variant="body2" bold>
          Body 2 semi bold
        </Body>

        <Body variant="body3">Body 3</Body>
        <Body variant="body3" bold>
          Body 3 semi bold
        </Body>

        <Body variant="like">Like</Body>

        <Body variant="control">Segmented control</Body>
        <Body variant="control" bold>
          Segmented control selected
        </Body>
      </Section>

      <Section title="New Fonts variants">
        <Heading /*color="body"*/>Body (default)</Heading>
        <Heading color="original">Original</Heading>
        <Heading color="daily">Daily</Heading>
        <Heading color="cocktails">Cocktails</Heading>
        <Heading color="light-and-fun">Light and Fun</Heading>
        <Heading color="kids">Kids</Heading>

        <Heading color="success">Success</Heading>
        <Heading color="error">Error</Heading>

        {[1, 2, 3, 4, 5, 6, 7].map(num => (
          <Heading key={num} color={`gray${num}`}>
            {`gray${num}`}
          </Heading>
        ))}

        <Heading color="#EA80CD">#EA80CD</Heading>
        <Heading color="#7269EB">#7269EB</Heading>
        <Heading color="#3BBAFF">#3BBAFF</Heading>

        <div
          style={{ backgroundColor: "#000", padding: 8, margin: "8px -8px" }}
        >
          <Heading color="white">White</Heading>
        </div>

        <br />
        <hr />
        <br />

        <Body>uppercase off (default)</Body>
        <Body uppercase>uppercase on</Body>

        <br />
        <hr />
        <br />

        <Heading>Align</Heading>
        <Body /*align="left"*/>(default) {loremIpsum}</Body>
        <br />
        <Body align="center">{loremIpsum}</Body>
        <br />
        <Body align="right">{loremIpsum}</Body>
        <br />
        <Body align="justify">{loremIpsum}</Body>
      </Section>
    </>
  )
}
