import React, { FC } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import commentIcon from "./commentIcon.svg"
import { Button, Heading, PremiumButton } from "~/components/ui"

import css from "./ButtonSample.module.scss"

const ButtonGroup: FC = props => <div className={css.buttonGroup} {...props} />

export default function ButtonSample() {
  return (
    <Section title="Buttons">
      <br />
      <Heading>Primary buttons</Heading>

      <ButtonGroup>
        <Button>Default Button</Button>
        <Button color="blue" icon={commentIcon}>
          blue Button
        </Button>
        <Button color="original" icon={commentIcon}>
          original Button
        </Button>
        <Button color="daily">daily Button</Button>
        <Button color="light-and-fun">lightAndFun Button</Button>
        <Button color="kids" icon={commentIcon}>
          kids Button
        </Button>
        <Button color="cocktails" icon={commentIcon}>
          cocktails Button
        </Button>
        <Button color="black">black Button</Button>
        <PremiumButton
          primary="Demarrer vos 3 jours d'essai"
          secondary="49,99/an après la fin de l'essai"
        />
        <Button disabled icon={commentIcon}>
          Disabled
        </Button>
      </ButtonGroup>

      <Heading>Custom colors (From HEX)</Heading>

      <ButtonGroup>
        <Button icon={commentIcon}>Default Button</Button>
        <Button color="#DD80B7">Button</Button>
        <Button color="#FFB629">Button</Button>
        <Button color="#aaccaa" icon={commentIcon}>
          Button
        </Button>
      </ButtonGroup>

      <br />
      <Heading>Secondary buttons</Heading>
      <ButtonGroup>
        <Button variant="secondary" icon={commentIcon}>
          Secondary
        </Button>
        <Button variant="secondary" disabled>
          Secondary disabled
        </Button>
        <Button variant="secondary" color="blue">
          blue Button
        </Button>
        <Button variant="secondary" color="original" icon={commentIcon}>
          original Button
        </Button>
        <Button variant="secondary" color="daily" icon={commentIcon}>
          daily Button
        </Button>
        <Button variant="secondary" color="kids">
          lightAndFun Button
        </Button>
        <Button variant="secondary" color="light-and-fun">
          kids Button
        </Button>
        <Button variant="secondary" color="cocktails" icon={commentIcon}>
          cocktails Button
        </Button>
        <Button variant="secondary" color="black" icon={commentIcon}>
          black Button
        </Button>
      </ButtonGroup>

      <Heading>Custom colors for secondary variant (From HEX)</Heading>

      <ButtonGroup>
        <Button variant="secondary">Default secondary button</Button>
        <Button variant="secondary" color="#DD80B7" icon={commentIcon}>
          Button
        </Button>
        <Button variant="secondary" color="#FFB629" icon={commentIcon}>
          Button
        </Button>
        <Button variant="secondary" color="#aaccaa">
          Button
        </Button>
      </ButtonGroup>

      <br />
      <Heading>Tertiary buttons</Heading>
      <ButtonGroup>
        <Button variant="tertiary" underline>
          Tertiary
        </Button>
        <Button variant="tertiary" underline disabled>
          Tertiary disabled
        </Button>
      </ButtonGroup>

      <br />
      <Heading>Button Props</Heading>

      <ButtonGroup>
        <Button size="small">Small Button</Button>
        <Button size="small" variant="secondary" color="original">
          Small Button
        </Button>
        <Button size="small" uppercase={false}>
          No uppercase
        </Button>
      </ButtonGroup>

      <div className={css.sectionBackground}>
        <Button color="white">White Button</Button>
        <Button variant="secondary" color="white">
          White & Secondary
        </Button>
      </div>

      <Button fullWidth>Full width Button</Button>
      <br />
      <Button fullWidth={[false, true]}>
        Full width Button on regular only
      </Button>
      <br />
      <br />
      <Button fullWidth={[true, false]}>
        Full width Button on compact only
      </Button>
    </Section>
  )
}
