import React, { CSSProperties } from "react"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import ButtonSample from "./ButtonSample/ButtonSample"
import CheckboxSample from "./CheckboxSample/CheckboxSample"
import ControlsSample from "./ControlsSample/ControlsSample"
import GridSample from "./GridSample/GridSample"
import ImageCardSample from "./ImageCardSample/ImageCardSample"
import LoaderSample from "./LoaderSample/LoaderSample"
import ModalSample from "./ModalSample/ModalSample"
import PillsSample from "./PillsSample/PillsSample"
import TabsSample from "./TabsSample/TabsSample"
import TooltipSample from "./TooltipSample/TooltipSample"
import TypographySample from "./TypographySample/TypographySample"
import VisibilityTransitionSample from "./VisibilityTransitionSample/VisibilityTransitionSample"
import SEO from "~/components/Seo/Seo"
import { Container, Heading } from "~/components/ui"
import Vertical from "~/models/Vertical"
import AvatarSample from "~/templates/design-system/AvatarSample/AvatarSample"

import css from "./design-system.module.scss"

function DesignSystem(props: TemplateDefaultPropTypes) {
  const currentThemeColor = Vertical.Original.color

  return (
    <>
      <SEO title="Design System" location={props.location} />

      <Container
        style={{ "--current-theme-color": currentThemeColor } as CSSProperties}
      >
        <header className={css.header}>
          <Heading variant="modal" Tag="h1" align="center" uppercase>
            Design System
          </Heading>
        </header>

        <ModalSample />
        <VisibilityTransitionSample />
        <ImageCardSample />
        <LoaderSample />
        <ControlsSample />
        <AvatarSample />
        <CheckboxSample />
        <PillsSample />
        <TypographySample />
        <GridSample />
        <ButtonSample />
        <TabsSample />
        <TooltipSample />
      </Container>
    </>
  )
}

export default withTemplate(DesignSystem)
