import React, { useState } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import chefclubLogoImage from "~/components/Layout/SearchLayout/assets/chefclubAuthorPicture.svg"
import chefclubLogoImageOnlyC from "~/components/Layout/SearchLayout/assets/chefclubAuthorPictureOnlyC.svg"
import { Body, Col, Row } from "~/components/ui"
import { Pills } from "~/components/ui/Pills/Pills"

export default function CheckboxSample() {
  const [isCheck, setIsCheck] = useState(false)
  return (
    <>
      <Section title="Pills">
        <Row>
          <Col width={[6, 6, 6]}>
            <Pills
              isRefined={isCheck}
              icon={isCheck ? chefclubLogoImageOnlyC : chefclubLogoImage}
              onclick={(event: any) => {
                event.preventDefault()
                setIsCheck(!isCheck)
              }}
              mainColor="var(--original-color)"
            >
              <Body
                variant="body2"
                semiBold
                color={isCheck ? "white" : "gray2"}
              >
                Chefclub
              </Body>
            </Pills>
          </Col>
        </Row>
      </Section>
    </>
  )
}
