import React, { useState } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import icon from "./icon.svg"
import { Tab } from "~/components/ui/Tabs/Tabs/Tab"
import { TabsView } from "~/components/ui/Tabs/TabsView/TabsView"

export default function TabsSample() {
  const [displayTab3, setDisplayTab3] = useState(true)

  return (
    <Section title="Tab">
      <TabsView>
        <Tab icon={icon} label="No icon tab">
          <p>Tab 1</p>
        </Tab>
        <Tab icon={icon} label="Icon tab">
          <p>Tab 2</p>
          <button onClick={() => setDisplayTab3(true)}>show tab 3</button>
        </Tab>
        {displayTab3 && (
          <Tab icon={icon} label="Optionnal tab">
            <p>Tab 3</p>
            <button onClick={() => setDisplayTab3(false)}>hide tab 3</button>
          </Tab>
        )}
      </TabsView>
    </Section>
  )
}
