import React, { FC } from "react"

import { Heading } from "~/components/ui"

import css from "./DesignSystemSection.module.scss"

interface PropTypes {
  title: string
}

const DesignSystemSection: FC<PropTypes> = ({ title, children }) => (
  <div className={css.section}>
    <Heading className={css.sectionTitle}>{title}</Heading>
    {children}
  </div>
)

export default DesignSystemSection
