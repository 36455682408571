import React, { FC } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import { Chip, Heading, InlineButton } from "~/components/ui"

import css from "./ControlsSample.module.scss"

const ControlsGroup: FC = props => (
  <div className={css.buttonGroup} {...props} />
)

export default function ControlsSample() {
  return (
    <Section title="Controls">
      <br />
      <Heading>InlineButton</Heading>

      <ControlsGroup>
        <InlineButton
          label="Partager"
          icon="share"
          onClick={() => console.log("clicked!")}
        />
        <InlineButton
          label="Sauvegarder"
          icon="bookmark"
          onClick={() => console.log("clicked!")}
        />
        <InlineButton
          label="Sauvegardée !"
          icon="bookmark"
          selected
          onClick={() => console.log("clicked!")}
        />
      </ControlsGroup>

      <br />
      <Heading>Chip</Heading>

      <ControlsGroup>
        <Chip text="Bolognaise" />
        <Chip text="Selected" selected />
        <Chip text="Disabled" disabled />
        <Chip text="Bolognaise + link" href="/design-system" />
        <Chip text="Selected + link" href="/design-system" selected />
        <Chip text="Disabled + Link" href="/design-system" disabled />
        <Chip text="Selected + Gatsby Link" to={"recipes"} selected />
        <Chip text="Disabled + Gatsby Link" to={"recipes"} disabled />
      </ControlsGroup>
    </Section>
  )
}
