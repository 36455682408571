import React, { FC } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import recipeSample from "./samplePartialRecipe.json"
import { Col, Heading, ImageCard, Row } from "~/components/ui"
import { getVerticalFromId } from "~/models/Vertical"

const ImageWrap: FC<{ title: string }> = ({ title, children }) => (
  <Col width={[12, 4]}>
    <br />
    <Heading variant="medium">{title}</Heading>
    {children}
  </Col>
)
export default function ImageCardSample() {
  return (
    <Section title="Image Cards">
      <Row>
        <ImageWrap title="Default card">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
          />
        </ImageWrap>

        <ImageWrap title="Recipe Card">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "recipe" }}
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </ImageWrap>

        <ImageWrap title="Recipe Without hover">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "recipe" }}
            disableHover
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </ImageWrap>

        <ImageWrap title="Recipe branded">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "branded" }}
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </ImageWrap>

        <ImageWrap title="Recipe premium">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "premium" }}
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </ImageWrap>

        <ImageWrap title="Recipe Play">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "play" }}
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </ImageWrap>

        <ImageWrap title="Reproduction">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "reproduction", payload: "16" }}
          />
        </ImageWrap>

        <ImageWrap title="From portrait image">
          <ImageCard
            imageProps={{
              src: "https://via.placeholder.com/500x1000",
            }}
          />
        </ImageWrap>

        <ImageWrap title="From landscape image">
          <ImageCard
            imageProps={{
              src: "https://via.placeholder.com/1000x500",
            }}
          />
        </ImageWrap>

        <ImageWrap title="Vertical">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "vertical", payload: recipeSample.vertical }}
          />
        </ImageWrap>

        <ImageWrap title="Not found with message">
          <ImageCard
            overlay={{ type: "not-found", payload: recipeSample.id }}
          />
        </ImageWrap>

        <ImageWrap title="Not found without message">
          <ImageCard overlay={{ type: "not-found" }} />
        </ImageWrap>
        <ImageWrap title="Full workshop">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "workshop", payload: "full" }}
          />
        </ImageWrap>
        <ImageWrap title="Completed workshop">
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "workshop", payload: "completed" }}
          />
        </ImageWrap>
      </Row>
      <Row>
        <Col width={3} offset={9}>
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "branded" }}
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </Col>
        <Col width={6} offset={6}>
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "branded" }}
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </Col>
        <Col width={9} offset={3}>
          <ImageCard
            imageProps={{
              src: recipeSample.webCover,
            }}
            overlay={{ type: "branded" }}
            color={getVerticalFromId(recipeSample.vertical).color}
          />
        </Col>
      </Row>
    </Section>
  )
}
