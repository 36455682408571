import React from "react"

import cn from "classnames"

import Section from "../DesignSystemSection/DesignSystemSection"
import { Body, Col, ColumnCount, Heading, Row } from "~/components/ui"

import css from "./GridSample.module.scss"

export default function GridSample() {
  return (
    <Section title="Grid">
      <Heading>Example:</Heading>
      <Row>
        <Col width={[12, 6]}>
          <pre>
            <code>
              {`
                <Row align? justify? reverse?>
                  <Col width? offset?>
                    ...
                  </Col>
                  <Col width? offset?>
                    ...
                  </Col>
                </Row>
              `}
            </code>
          </pre>
        </Col>
        <Col width={[12, 6]}>
          <pre>
            <code>
              {`
                // Responsive
                <Col width={[12, 4, 3]}>
                  ...
                </Col>
              `}
            </code>
          </pre>
        </Col>
      </Row>

      <Heading>Responsive</Heading>
      <Body>
        <code>.colCompact12 .colRegular6 .colWide3</code>
      </Body>

      <Row>
        <Col width={[12, 6, 3]}>
          <div className={css.box}></div>
        </Col>
        <Col width={[12, 6, 3]}>
          <div className={css.box}></div>
        </Col>
        <Col width={[12, 6, 3]}>
          <div className={css.box}></div>
        </Col>
        <Col width={[12, 6, 3]}>
          <div className={css.box}></div>
        </Col>
      </Row>

      <Heading>
        Offset0 & col0. Format: offset|col (for each breakpoint)
      </Heading>

      <Row>
        <Col offset={[0, 1]} width={[0, 5, 4]}>
          <div className={css.box}>0.1|0.5.4</div>
        </Col>
        <Col offset={[1, 2, 0]} width={[6, 0, 4]}>
          <div className={css.box}>1.2.0|6.0.4</div>
        </Col>
        <Col offset={[0, 0, 0]} width={[5, 4, 3]}>
          <div className={css.box}>0.0.0|5.4.3</div>
        </Col>
      </Row>

      <Heading>Responsive offset</Heading>

      <Body>
        <code>
          .colCompactOffset1 .colCompact10 .colRegularOffset2 .colRegular8
          .colWideOffset3 .colWide6
        </code>
      </Body>

      <Row>
        <Col width={[10, 8, 6]} offset={[1, 2, 3]}>
          <div className={css.box}></div>
        </Col>
      </Row>

      <Heading>Fluid</Heading>
      <Body>
        <code>Example: .col, .colCompact6, etc</code>
      </Body>

      {[0, 1, 2, 3, 4, 5, 6].map(i => (
        <Row key={i}>
          <Col width={i as ColumnCount}>
            <div className={css.box}>{`col ${i}`}</div>
          </Col>
          <Col width={(12 - i) as ColumnCount}>
            <div className={css.box}>{`col ${12 - i}`}</div>
          </Col>
        </Row>
      ))}

      <Heading>Offset</Heading>
      <Body>
        <code>.colRegular2 .colRegularOffset10</code>
      </Body>

      <Row>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
          <Col
            key={i}
            width={i as ColumnCount}
            offset={(12 - i) as ColumnCount}
          >
            <div className={css.box}>{`col ${i} + offset ${12 - i}`}</div>
          </Col>
        ))}
      </Row>

      <Heading>Row justify</Heading>
      <Body>
        <code>Example: .row + .start|.center|.end|.around|.between</code>
      </Body>

      <Row justify="start">
        <Col width={4}>
          <div className={css.box}>Start</div>
        </Col>
      </Row>
      <Row justify="center">
        <Col width={4}>
          <div className={css.box}>Center</div>
        </Col>
      </Row>
      <Row justify="end">
        <Col width={4}>
          <div className={css.box}>End</div>
        </Col>
      </Row>
      <Row justify="around">
        <Col width={4}>
          <div className={css.box}>Around</div>
        </Col>
        <Col width={4}>
          <div className={css.box}>Around</div>
        </Col>
      </Row>
      <Row justify="between">
        <Col width={4}>
          <div className={css.box}>Between</div>
        </Col>
        <Col width={4}>
          <div className={css.box}>Between</div>
        </Col>
      </Row>

      <Body>{`Responsive: Center > Between > Around`}</Body>
      <Row justify={["center", "between", "around"]}>
        <Col width={4}>
          <div className={css.box}></div>
        </Col>
        <Col width={4}>
          <div className={css.box}></div>
        </Col>
      </Row>

      <Heading>Row align</Heading>
      <Body>
        <code>Example: .row + .start|.center|.end|.around|.between</code>
      </Body>

      <Row align="top">
        <Col width={[6, 4]} offset={[, 2]}>
          <div className={cn(css.box, css.large)}></div>
        </Col>
        <Col width={[6, 4]}>
          <div className={css.box}>Top</div>
        </Col>
      </Row>
      <Row align="middle">
        <Col width={[6, 4]} offset={[, 2]}>
          <div className={cn(css.box, css.large)}></div>
        </Col>
        <Col width={[6, 4]}>
          <div className={css.box}>Middle</div>
        </Col>
      </Row>
      <Row align="bottom">
        <Col width={[6, 4]} offset={[0, 2]}>
          <div className={cn(css.box, css.large)}></div>
        </Col>
        <Col width={[6, 4]}>
          <div className={css.box}>Bottom</div>
        </Col>
      </Row>
      <Row align={["top", "middle", "bottom"]}>
        <Col width={[6, 4]} offset={[0, 2]}>
          <div className={cn(css.box, css.large)}>
            <Body color="inherit">Compact: Top</Body>
            <Body color="inherit">Regular: Middle</Body>
            <Body color="inherit">Wide: Bottom</Body>
          </div>
        </Col>
        <Col width={[6, 4]}>
          <div className={css.box}></div>
        </Col>
      </Row>

      <Heading>Row reverse</Heading>
      <Body>
        <code>Example: .row + .reverse</code>
      </Body>

      <Row reverse>
        <Col width={6}>
          <div className={css.box}>1</div>
        </Col>
        <Col width={6}>
          <div className={css.box}>2</div>
        </Col>
      </Row>

      <Heading>Row reverse responsive</Heading>
      <Body>
        <code>Example: Compact: Reverse; Regular: Normal; Wide: Reverse</code>
      </Body>
      <Row reverse={[true, false, true]}>
        <Col width={6}>
          <div className={css.box}>1</div>
        </Col>
        <Col width={6}>
          <div className={css.box}>2</div>
        </Col>
      </Row>

      <Heading>Order: First and/or Last</Heading>
      <Body>
        <code>{`Example: <Col first />`}</code>
      </Body>

      <Row>
        <Col width={[12, 3]}>
          <div className={css.box}>1</div>
        </Col>
        <Col width={[12, 3]}>
          <div className={css.box}>2</div>
        </Col>
        <Col width={[12, 3]}>
          <div className={css.box}>3</div>
        </Col>
        <Col width={[12, 3]} first>
          <div className={css.box}>4 (first)</div>
        </Col>
      </Row>

      <Body>
        <code>{`Example: <Col last />`}</code>
      </Body>
      <Row>
        <Col width={[12, 3]} last>
          <div className={css.box}>1 (last)</div>
        </Col>
        <Col width={[12, 3]}>
          <div className={css.box}>2</div>
        </Col>
        <Col width={[12, 3]}>
          <div className={css.box}>3</div>
        </Col>
        <Col width={[12, 3]}>
          <div className={css.box}>4</div>
        </Col>
      </Row>
    </Section>
  )
}
