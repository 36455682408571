import React, { HTMLProps } from "react"

import cn from "classnames"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./Checkbox.module.scss"

interface PropTypes extends HTMLProps<HTMLInputElement> {
  label: string
  value: string[]
  defaultChecked: boolean
  href?: string
  labelProps?: any
  onclick(event: any): void
}

const Checkbox = ({
  children,
  label,
  value,
  defaultChecked,
  onclick,
  disabled,
  className,
  href,
  labelProps,
}: PropTypes) => {
  const LabelTag = href ? "a" : "span"

  return (
    <div
      className={cn(
        css.checkbox,
        disabled ? css.disabled : undefined,
        className
      )}
    >
      <input
        id={label}
        data-value={value}
        type="checkbox"
        defaultChecked={defaultChecked}
        onClick={disabled ? undefined : onclick}
        disabled={disabled}
      />
      <label data-value={value} htmlFor={label} tabIndex={-1}>
        <LabelTag className={css.itemLabel} href={href} {...labelProps}>
          {children}
        </LabelTag>
      </label>
    </div>
  )
}

export default Checkbox
