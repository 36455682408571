import React from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import { Loader } from "~/components/ui"

export default function LoaderSample() {
  return (
    <Section title="Loader">
      <div style={{ height: 300 }}>
        <Loader />
      </div>
    </Section>
  )
}
