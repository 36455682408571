import React, { useState } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import Modal from "~/components/Modals/Modal"
import { Col, Heading, Row } from "~/components/ui"

export default function ModalSample() {
  const [openFixed, setOpenFixed] = useState(false)
  const [openAbsolute, setOpenAbsolute] = useState(false)

  return (
    <Section title="Modal (unMount on exit)">
      <Modal open={openAbsolute} mode="absolute">
        {() => (
          <div
            style={{
              width: "90%",
              maxWidth: 600,
              margin: "2em auto",
              background: "white",
              padding: 40,
            }}
          >
            <Heading>Absolute Modal</Heading>
            <button onClick={() => setOpenAbsolute(false)}>Close</button>
          </div>
        )}
      </Modal>
      <Row>
        <Col width={[12, 6]}>
          <button
            type="button"
            style={{ marginBottom: 16 }}
            onClick={() => setOpenFixed(true)}
          >
            Open Fixed Modal
          </button>
          <Modal open={openFixed} mode="fixed">
            {() => (
              <div
                style={{
                  width: "90%",
                  maxWidth: 600,
                  margin: "auto",
                  background: "white",
                  padding: 40,
                }}
              >
                <Heading>Fixed Modal</Heading>
                <button onClick={() => setOpenFixed(false)}>Close</button>
              </div>
            )}
          </Modal>
        </Col>
        <Col width={[12, 6]}>
          <button
            type="button"
            style={{ marginBottom: 16 }}
            onClick={() => setOpenAbsolute(true)}
          >
            Open absolute modal
          </button>
        </Col>
      </Row>
    </Section>
  )
}
