import React, { FC } from "react"

import Section from "../DesignSystemSection/DesignSystemSection"
import { Body, Col, Heading, Row } from "~/components/ui"
import Tooltip from "~/components/ui/Tooltip/Tooltip"

import css from "./TooltipSample.module.scss"

const TooltipParent: FC = props => (
  <div className={css.tooltipParent} {...props} />
)

export default function TooltipSample() {
  return (
    <Section title="Tooltip">
      <Heading>Permanent tooltips</Heading>
      <TooltipParent>
        <Row>
          <Col width={[2, 2]} offset={[0, 2]}>
            <Tooltip right={0}>
              <Body variant="body2" semiBold color="white">
                Short text
              </Body>
            </Tooltip>
          </Col>

          <Col width={[4, 4]} offset={[1, 2]}>
            <Tooltip bgColor={"blue"} right={0}>
              <Body variant="body2" semiBold color="yellow">
                Blue background, yellow text
              </Body>
            </Tooltip>
          </Col>
        </Row>
      </TooltipParent>

      <TooltipParent>
        <Row>
          <Col width={2} offset={[1, 3]}>
            <Tooltip bgColor={"black"} right={0}>
              <Body variant="body2" semiBold color="white">
                Black background with a somewhat long text
              </Body>
            </Tooltip>
          </Col>
        </Row>
      </TooltipParent>

      <Heading>Temporary tooltips (3rd one linked to localStorage)</Heading>
      <TooltipParent>
        <Row>
          <Col width={1} offset={[0, 1]}>
            <Tooltip appearingDelay={2} right={0}>
              <Body variant="body2" semiBold color="white">
                Appears after 2 seconds
              </Body>
            </Tooltip>
          </Col>

          <Col width={4} offset={[6, 3]}>
            <Tooltip appearingDelay={3} disappearingDelay={8} right={0}>
              <Body variant="body2" semiBold color="white">
                Appears for 5 seconds
              </Body>
            </Tooltip>
          </Col>

          <Col width={3} offset={8}>
            <Tooltip
              appearingDelay={1}
              showOnce
              id={"design-system-sample"}
              right={0}
            >
              <Body variant="body2" semiBold color="white">
                Appears once per visit
              </Body>
            </Tooltip>
          </Col>
        </Row>
      </TooltipParent>
    </Section>
  )
}
